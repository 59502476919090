import { convertArrayToMakeOptions } from "shared/utils/common";
import { MAKES_TO_SKIP } from "../config/common";

const makeOptions = (makeModelYearMap) => {
  if (!makeModelYearMap || !Object.keys(makeModelYearMap)?.length) {
    return [];
  }

  const options = Object.keys(makeModelYearMap).filter((make) => (
    !MAKES_TO_SKIP.includes(make)));

  return convertArrayToMakeOptions(options);
};

export default makeOptions;
