export const KEYS_TO_SKIP = [
  "service_writer_id",
  "dispatch_code",
  "labor_type",
  "printer_id",
  "shared_account",
  "imported_makes",
];

export const MAKES_TO_SKIP = [" make", "Unknown"];
